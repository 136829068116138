<template>
  <v-list-item>
    <v-list-item-title>
<!--      <a @click="copyStep()">
        <v-card class="pa-2 mt-3 elevation-0">
          <v-icon class="mr-2 mt-n1">mdi-content-copy</v-icon>{{language.componentLabel.titleCopyToOther}}
        </v-card>
      </a>-->
      <addField
          path="serve.php?f=administration&f2=projectAdministration"
          :additionalAttributes="additionalAttributes"
          :showDialog="openDialog"
          @dialog-closed="openDialog = false"
      >
        <v-card class="pa-2 mt-3 elevation-0">
          <v-icon class="mr-2 mt-n1">mdi-content-copy</v-icon>{{language.componentLabel.titleCopyToOther}}
        </v-card>
      </addField>
    </v-list-item-title>
  </v-list-item>
</template>
<script>
import addField from "@/commonComponents/addField.vue";
export default {
  data() {
    return { openDialog: false };
  },
  // this.$emit("close-menu");
  components: { addField },
  props: ["currentTable", "selectedRows"],
  computed: {
    additionalAttributes() {
      return {
        function: "getProjects",
        requestType: "ajax",
        selected: Object.keys(this.selectedRows) /*, 'hideButtons':'true'*/,
        requiredPage: this.currentTable.tableUrl,
      };
    },
  },
  methods: {
    copyStep() {
      if (Object.keys(this.selectedRows).length > 0) {
        this.$emit("show-warning", false);
        this.openDialog = true;
      } else {
        this.$emit("show-warning", true);
      }
    },
  },
};

//  function copyObjects(caller,single){
//     let selected=[];
//     if(single==false){
//       $('.selectedElements:checked').each(function(){
//         selected.push($(this).val());
//       });
//     }else {
//       selected.push(single);
//     }
//     if (selected.length>0){
//       var classList = "unsetZ-index";
//       // pop up window to select the object to copy to
//       let  options={  'function':'getProjects',
//         'requestType':'ajax' ,
//         selected: selected/*, 'hideButtons':'true'*/,
//         requiredPage :location.href };
//       let sentURI="serve.php?f=administration&f2=projectAdministration";
//       let  dialogOptions  ={  width: 550 ,
//                               dialogClass: classList ,
//                               at:"center",
//                               my:  "center+"+(window.dialogWindow*10)+" top+"+((window.dialogWindow*100)) };
//       let title="Copy to Project";
//       let $returnedDialog  = getDialog(sentURI,options,dialogOptions,title,true);
//     }else{
//       showWarn();
//     }
//   }
</script>